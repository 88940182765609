<template>
  <div class="content">
    <table class="table table-google">
      <thead>
        <tr>
          <th class="text-nowrap">Position</th>
          <th class="text-nowrap">Plant</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data,position) in positions" :key="position">
          <td class="row-fit">
            <router-link :to="`/pos/${position}`" class="text-blue">
              <span class="text-nowrap">{{ position }}</span>
            </router-link>
          </td>
          <td>{{ data[0].HerbName }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'Positions',
  components: {},
  data() {
    return {
      loadingCount: 0,
      positions: {},
    };
  },
  methods: {
    fetchPositions() {
      const params = {
        limit: 1000,
      };
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'sana.move' })
        .send({ method: 'procMoves' })
        .send({ params })
        .then((res) => {
          this.positions = res.body;
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchAll() {
      this.fetchPositions();
    },
  },
  created() {
    this.fetchAll();
  },
};
</script>

<style lang="scss" scoped>
</style>
